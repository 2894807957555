import React, { Component } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { TitlePageHeading, HeaderStyle } from "./style";
import "../../pages/style.css";

import CategoryPopper from "./CategoryPopper";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { connect } from "react-redux";
import PrRFQSettings from "../PRRFQ/RfqSettings";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { CustomDropzone, CustomsmallDialog } from "../Dialogs/Upload/style";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import {
  getTemplates,
  importRfqExcel,
  devRfqExcelURi,
  RfqExcelURi,
} from "../../config/apiUrl";
import { openSnackbar } from "../../redux/actions/snackbarAction";
import { setSelectedItem } from "../../redux/actions/itemActions";
import { setSelectedCategory } from "../../redux/actions/categoryAction";
import { setSelectedVendors } from "../../redux/actions/vendorActions";
import { ImportExcelRfq } from "../../redux/actions/importExcelAction";
import { ALLOWED_COMPANY } from "../../config/rfq-excel-config";
 import { setSessionId } from "../../redux/actions/sessionAction";

class Header extends Component {
  state = {
    open: false,
    value: null,
    inputValue: "",
    OpenRfqExcel: false,
    rfqType: "",
    templateOptions: [],
    selectedTemplate: {
      id: "",
      name: "",
      batch_id: "",
    },
    validFilesList: [],
    excelResponse: {},
    loader: false,
    ErrContent: "",
  };

  handleClose = () => {
    this.setState({
      open: false,
      value: null,
      inputValue: "",
    });
  };
  setRfqTypes = () => {

    if (
      this.props.accountModulePermission[
        "Not Allowed for Category Specific RFQ"
      ]?.module_activated === "Y"
    ) {
      this.setState({
        rfqType: 20,
    
      });
    } else {
     if (
        this.props.accountModulePermission["Itemize Vendor Specific RFQ"]
          ?.module_activated === "Y"
      ) {
        if (
          this.props.featurePermission?.category_specific_rfq?.feature_value ===
          "Y"
        ) {
          // set default value based on the feature-permission key
          this.setState({
            rfqType: 10,
       
          });
        } else {
          this.setState({
            rfqType: 20,
          
          });
        }
      } else {
        this.setState({
          rfqType: 10,
     
        });
      }
    }
  };

  handleChange = (event, newValue) => {
    const { options } = this.state;
    this.setState({
      options: newValue ? [newValue, ...options] : options,
      value: newValue,
    });
  };
  fetchTempates = async () => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    // this.setState({
    //   templateLoading: true,
    // });

    await axios
      .get(getTemplates)
      .then((res) => {
        let templateData = res.data.data;
        let templateName = [
          "AIA_CONTRACT_GST_R2A_1.0",
          "AIA_CONTRACT_GST_R2A_1.0_09082024",
        ];
        const FinalTemplates = templateData.filter((template) => {
          // return templateName.includes(template.name);
           return template.name
        });
        if (FinalTemplates.length === 1) {
          this.setState({
            selectedTemplate: FinalTemplates,
          });
          this.props.selectedTemplateDataFromExcel(FinalTemplates);
        }
        this.setState({
          templateOptions: FinalTemplates,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  handleTemplateChange = async (eve, value) => {
    if (value !== null) {
      this.setState({
        selectedTemplate: value,
        // templateLoading: false,
      });
    }
  };
  downloadSampleFile = (value) => {
    console.log("value",value);
    const { id, batch_id,name } = value;
    const rfq_type = this.state.rfqType; // Assuming rfq_type is constant, adjust as needed
    const url = `${importRfqExcel}?template_id=${id}&batch_id=${batch_id}&rfq_type=${rfq_type}&{filename}=${name}`;
    const queryParams = new URLSearchParams(window?.location?.search);
    let SessionID = queryParams.get('q');
    // const sessionId = '1727151709173490.795654018313453';
    let fileName;
    fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${SessionID}`,
        },
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    })
    .then((data) => {
        const downloadLink = data.download_link;
        fileName = data.file_name;
        const name = data.name; 
        return fetch(downloadLink, {
            method: 'GET',
        });
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error(`Error fetching file! Status: ${response.status}`);
        }
        return response.blob();
    })
    .then((blob) => {
        const link = document.createElement('a');
        const blobUrl = window.URL.createObjectURL(blob); // Create a blob URL
        link.href = blobUrl;
        link.setAttribute('download',fileName); 
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
        console.error('Error fetching file:', error);
    });
};

  

  handleFilesChange = (newFile) => {
    let validFilesList = [];
    newFile.forEach((file) => {
      if (!file.name.match(/\.(xlsx)$/i)) {
        this.props.openSnackbar(
          "error",
          ` Invalid file type Please upload only XLSX files.`
        );
      } else if (file.size > 26214400) {
        this.props.openSnackbar("error", ` File size cannot exceed 25 MB.`);
      } else {
        // setFileUploadErr(false);
        validFilesList.push(file);
      }
    });

    return validFilesList;
  };
  handleSubmit = () => {
    this.props.selectedTemplateDataFromExcel("");
    this.props.setSelectedCategory(null);
    this.props.setSelectedItem([]);
    this.props.setSelectedVendors([]);
    this.props.setSelectedVendors([]);

    this.props.ImportExcelRfq({});
    this.props.setRfqType("");
    this.setState({
      loader: true,
    });

    let formData = new FormData();

    formData.append("template_id", this.state.selectedTemplate.id);
    formData.append("batch_id", this.state.selectedTemplate.batch_id);
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    this.state.validFilesList.forEach((file) => {
      // formData.append("files[]", file);
      formData.append("file", file);
    });
    axios
      .post(importRfqExcel, formData) // Set responseType to 'arraybuffer' to receive binary data
      .then((res) => {
        this.setState({
          loader: false,
        });
        let notFoundMessages = []; // Array to store all messages

        if (res.data?.error == 1) {
          if (
            res.data.hasOwnProperty("validations") &&
            res.data?.validations.length > 0
          ) {
            notFoundMessages.push(res.data?.validations.join("<br/>"));
          }
          if (
            res.data.hasOwnProperty("missing_data") &&
            Object.keys(res.data?.missing_data).length > 0
          ) {
            if (res.data?.missing_data.hasOwnProperty("category_code")) {
              notFoundMessages.push(
                `Category ${res.data?.missing_data?.category_code} not found.`
              );
            }

            if (res.data?.missing_data.hasOwnProperty("items")) {
              const itemsMessage = Object.entries(res.data?.missing_data?.items)
                .map(([key, value]) => `Item ${value} not found.`)
                .join("<br/> ");
              notFoundMessages.push(itemsMessage);
            }

            if (res.data?.missing_data.hasOwnProperty("vendor")) {
              const vendorMessage = Object.entries(
                res.data?.missing_data?.vendor
              )
                .map(([key, value]) => `Vendor ${value} not found.`)
                .join("<br/> ");
              notFoundMessages.push(vendorMessage);
            }

            if (res.data?.missing_data.hasOwnProperty("item_mapping")) {
              const itemMappingMessage = Object.entries(
                res.data?.missing_data?.item_mapping
              )
                .map(
                  ([key, value]) => `Item ${value} is not linked with category.`
                )
                .join("<br/> ");
              notFoundMessages.push(itemMappingMessage);
            }

            if (res.data?.missing_data.hasOwnProperty("vendor_mapping")) {
              const vendorMappingMessage = Object.entries(
                res.data?.missing_data?.vendor_mapping
              ) // Corrected to vendor_mapping
                .map(
                  ([key, value]) =>
                    `Vendor ${value} is not linked with category.`
                )
                .join("<br/> ");
              notFoundMessages.push(vendorMappingMessage);
            }
          }

          // Concatenate all messages into a single string
          const notFoundMessage = notFoundMessages.join("<br/> ");
          if (notFoundMessage.length > 0) {
            this.setState({
              ErrContent: notFoundMessage,
              validFilesList: [],
            });
          }
        } else {
          this.props.setSelectedCategory(res.data?.category);
          this.props.setSelectedItem(res.data?.items);
          if (res.data?.form_data?.rfqtype === "Category Specific RFQ") {
            if (
              this.props.rfqSettingValues?.vendorMasterPaymentTerm &&
              this.props.rfqSettingValues?.vendorMasterIncoTerm
            ) {
              const filteredVendor = res.data.vendor.filter((vendor) => {
                return (
                  vendor?.inco_term_info?.inco_term_code != null &&
                  vendor?.payment_term_info?.payment_term_code != null &&
                  vendor?.email != null
                );
              });
              this.props.setSelectedVendors(filteredVendor);
            } else if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
              const filteredVendor = res.data.vendor.filter((vendor) => {
                return (
                  vendor?.payment_term_info?.payment_term_code != null &&
                  vendor?.email != null
                );
              });
              this.props.setSelectedVendors(filteredVendor);
            } else if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
              const filteredVendor = res.data.vendor.filter((vendor) => {
                return (
                  vendor?.inco_term_info?.inco_term_code != null &&
                  vendor?.email != null
                );
              });
              this.props.setSelectedVendors(filteredVendor);
            } else {
              const filteredVendor = res.data.vendor.filter((vendor) => {
                return vendor?.email != null;
              });

              this.props.setSelectedVendors(filteredVendor);
            }
          }
          this.props.selectedTemplateDataFromExcel(this.state.selectedTemplate);

          this.props.ImportExcelRfq(res.data?.form_data);
          this.props.setRfqType(res.data?.form_data?.rfqtype);
          this.setState({
            OpenRfqExcel: false,
            validFilesList: [],
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.props.openSnackbar("error", ` Something went wrong!`);
        this.setState({
          loader: false,
        });
      });
  };
  render() {
    const { validFilesList } = this.state; // Destructure validFilesList from the state
    const currentHost = window.location.host;
    const allowdCompany = ALLOWED_COMPANY[currentHost] || ALLOWED_COMPANY["default"]
    const allowRFQExcel = allowdCompany.includes(parseInt(this.props.companyInfo?.business_gr_master_id))

    const files = validFilesList.map((file) => (
      <ListItem
        key={file.path}
        sx={{
          background: "var(--grey-100)",
          borderRadius: "4px",
          marginBottom: "10px",
          padding: "2px 10px",
          color: "text.primary",
        }}
      >
        <Tooltip title={file.path}>

        <ListItemText
          primary={file.path}
          secondary={
            file.size > 1048575
              ? Math.round((file.size * 100.0) / 1048576) / 100 + " MB"
              : Math.round((file.size * 100.0) / 1024) / 100 + " KB"
          }
          sx={{ ml: 1 }}
          primaryTypographyProps={{
            overflow: "hidden",
            width: "calc(100% - 36px)",
            fontSize: "14px",
          }}
        />
        </Tooltip>
      </ListItem>
    ));
    return (
      <>
        {HeaderStyle}

        {
          <PrRFQSettings
            key={this.props.TemporaryDrawerKey}
            open={this.state.open}
            sessionId={this.props.sessionID}
            handleClose={this.handleClose}
            triggerTemplateIFrame={this.props.triggerTemplateIFrame}
            groupRFQ={false}
          />
        }
        {/** 
          <TemporaryDrawer
            key={this.props.TemporaryDrawerKey}
            open={this.state.open}
            sessionId={this.props.sessionID}
            handleClose={this.handleClose}
          />
          */}
        <Box className="page-header" position="sticky">
          <Grid container className="main_header_inner1" alignItems="flex-end">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="search-box"
              >
                <TitlePageHeading
                  color="textPrimary"
                  variant="h5"
                  className="page_heading"
                >
                  Create RFQ
                </TitlePageHeading>
                <CategoryPopper
                  resetSettingDrawer={this.props.resetSettingDrawer}
                  isSuperUser={this.props.userInfo?.is_super_user === "Y"}
                  categoryMasterPermission={
                    this.props.masterPermission?.category_master === "Y"
                  }
                />
              </Box>
            </Grid>

            <Grid item lg={6} md={6} sm={3} xs={3} className="text-right">
              { <Button
                variant="text"
                sx={{ backgroundColor: "var(--primary-50)" }}
                startIcon={<FileUploadOutlinedIcon />}
                onClick={() => {
                  this.setState({ OpenRfqExcel: true });
                  this.fetchTempates();
                  this.setRfqTypes()
                }}
              >
                                UPLOAD VIA EXCEL
              </Button>}
              {this.props.selectedCategory && (
                <Tooltip title="RFQ Setting">
                  <IconButton
                    size="medium"
                    variant="icon-contained"
                    onClick={() => this.setState({ open: true })}
                    sx={{ ml: 1 }}
                  >
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Box>
        <Grid container>
          <Grid item lg={12}>
            <CustomsmallDialog
              scroll="body"
              maxWidth={"sm"}
              open={this.state.OpenRfqExcel}
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                style: { 
                  overflow: 'visible',
                  verticalAlign: 'top' // Corrected syntax for vertical-align
                }
              }}
              
              
            >
              {this.state.ErrContent ? (
                <>
                  {" "}
                  <DialogTitle
                    className="dialogcard_header_main"
                    sx={{
                      "&:focus-visible": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                    tabIndex={-1}
                  >
                    {"Errors"}
                  </DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
                    {/* <Typography sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }}>
               {errContent}
              </Typography> */}
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.ErrContent,
                        }}
                      />
                    }
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="outlined"
                      color="primary"
                      autoFocus
                      onClick={() => {
                        this.setState({
                          OpenRfqExcel: false,
                          ErrContent: "",
                          validFilesList: [],
                        });
                      }}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogTitle
                    className="dialogcard_header_main"
                    sx={{
                      "&:focus-visible": {
                        border: "none",
                        outline: "none",
                      },
                    }}
                    tabIndex={-1}
                  >
                    Import Form Via Excel
                  </DialogTitle>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      this.setState({
                        OpenRfqExcel: false,
                        rfqType: "20",
                        validFilesList: [],
                      });
                    }}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent>
                      <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      // defaultValue="20" // Default to "Item specific RFQ"
                       value={this.state.rfqType} // Set this value based on state
                      onChange={(e) => {
                        this.setState({ rfqType: e.target.value });
                      }}
                    >
                      <FormControlLabel
                        value="10"
                        control={<Radio />}
                        label="Category specific RFQ"
                      />
                      <FormControlLabel
                        value="20"
                        control={<Radio />}
                        label="Item specific RFQ"
                      />
                    </RadioGroup>

                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={this.state.templateOptions || []}
                      value={this.state.selectedTemplate}
                      onChange={this.handleTemplateChange}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          placeholder="Select Template"
                          inputProps={{
                            ...params.inputProps,
                            // "data-batch-id": selTemplate?.batch_id,
                            // "data-template-id": selTemplate?.id,
                          }}
                        />
                      )}
                      size="small"
                      sx={{
                        background: "var(--grey-0)",
                        marginBottom: 2, // Add space below the Autocomplete
                      }}
                    />

                    {this.state.selectedTemplate?.id != "" && (
                      <>
                        <div className="dropzone">
                          <CustomDropzone
                            accept=".xlsx"
                            // maxFiles: 1,
                            maxFiles={1} // Set maxFiles to 1 to allow only one file
                            onDrop={(acceptedFiles) => {
                              let validFilesList = [];

                              validFilesList =
                                this.handleFilesChange(acceptedFiles);
                              if (validFilesList.length > 0) {
                                this.setState({
                                  validFilesList,
                                });
                              }
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dz-message document_upload_main">
                                  <div className="drop_zone_area">
                                    <Typography variant="p">
                                      <CloudUploadIcon sx={{ mr: 1 }} /> Drag
                                      and Drop or <span>Browse</span> to upload
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            )}
                          </CustomDropzone>
                        </div>

                        <Typography
                          sx={{ fontSize: "14px", fontWeight: 500, mt: 2 }}
                        >
                          Download Sample Format:{" "}
                          <Link
                            // href={
                            //   process.env.NODE_ENV === "production"
                            //     ? RfqExcelURi(
                            //         this.props.userInfo.division_id,
                            //         this.props.companyInfo.display_name,
                            //         this.state.rfqType === "10"
                            //           ? `${this.state.selectedTemplate?.name}_categorized`
                            //           : `${this.state.selectedTemplate?.name}_itemized`
                            //       )
                            //     : devRfqExcelURi(
                            //         this.props.userInfo.division_id,
                            //         this.props.companyInfo.display_name,
                            //         this.state.rfqType === "10"
                            //           ? `${this.state.selectedTemplate?.name}_categorized`
                            //           : `${this.state.selectedTemplate?.name}_itemized`
                            //       )
                            // }
                            target="_blank" // Open in a new tab
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              this.downloadSampleFile(this.state.selectedTemplate);
                            }}
                          >
                            Download
                          </Link>
                        </Typography>
                        <Box className="FileUploadList" mt={1} mb={2}>
                          <List sx={{ p: "0" }}>{files}</List>
                        </Box>

                        <Typography
                          sx={{ fontSize: "12px", color: "grey.500" }}
                        >
                          Note: The file should be of type(*.xlsx)
                        </Typography>
                      </>
                    )}
                  </DialogContent>
                  {this.state.selectedTemplate?.id != "" && (
                    <DialogActions>
                      <Button
                        variant="contained"
                        disabled={
                          this.state.loader ||
                          this.state.validFilesList.length === 0
                        }
                        onClick={this.handleSubmit}
                        startIcon={
                          this.state.loader && (
                            <CircularProgress color="inherit" size="1rem" />
                          )
                        }
                        color="primary"
                      >
                        Upload
                      </Button>
                    </DialogActions>
                  )}
                </>
              )}
            </CustomsmallDialog>
          </Grid>
        </Grid>
      </>
    );
  }
}
const mapDispatchToProps = {
  openSnackbar,
  setSelectedItem,
  setSelectedCategory,
  setSelectedVendors,
  ImportExcelRfq,
};
const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  userInfo: state.userInfoReducer.userInfo,
  masterPermission: state.permissionsReducer.masterPermission,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  featurePermission: state.permissionsReducer.featurePermission,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
